import React, { useState, useEffect, useContext, useRef } from "react";
import "./PostActions.scss";
import axios from "axios";
import ConfigContext from "../../ConfigContext";

const PostActions = ({
  postId,
  condensed,
  light,
  openOriginal,
  postTitle,
  publishDate,
  isVideo,
  openModal,
  statistics,
}) => {
  const [liked, setliked] = useState(false);
  const [showOptions, setshowOptions] = useState(false);
  const shareRef = useRef(null);
  const buttonClass = light ? "btn btn-outline-light" : "";
  const { configData } = useContext(ConfigContext);

  const changeShowOption = (e) => {
    if (shareRef.current) {
      if (e.target.id !== shareRef.current.id) {
        //element clicked wasn't the div; hide the div
        shareRef.current.style.display = "none";
      }
    }
  };
  useEffect(() => {
    if (showOptions) {
      document.addEventListener("click", changeShowOption);
    }

    // return () => {
    //   document.removeEventListener('click', this.changeShowOption)
    // }
  }, [showOptions]);

  const learnMore = (event) => {
    event.preventDefault();
    if (openOriginal) {
      window.open(
        `https://amgennews.amgen.com/member/post/${postId}`,
        "_blank"
      );
      const newspayload = [
        {
          userId: localStorage.getItem("registerE"),
          userName: localStorage.getItem("userName"),
          postId: postId,
          postTitle: postTitle,
          articleReadTimestamp: new Date(Date.now()).getTime(),
          className: "article-read-on-amgen",
          publishDate: publishDate,
          dateTime: new Date(Date.now()).toISOString(),
          department: localStorage.getItem("department").split("/")[1].trim(),
        },
      ];
      axios
        .post(process.env.REACT_APP_API_URL, JSON.stringify(newspayload), {
          headers: {
            "x-api-key": process.env.REACT_APP_X_API_KEY,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          console.log("article amgen news read success");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      openModal();

      // const payload = [
      //   {
      //     userId: localStorage.getItem("registerE"),
      //     userName: localStorage.getItem("userName"),
      //     postId: postId,
      //     postTitle: postTitle,
      //     articleReadTimestamp: new Date(Date.now()).getTime(),
      //     className: "article-read",
      //     publishDate: publishDate,
      //     dateTime: new Date(Date.now()).toISOString(),
      //     department: localStorage.getItem("department").split("/")[1].trim(),
      //   },
      // ];
      // axios
      //   .post(process.env.REACT_APP_API_URL, JSON.stringify(payload), {
      //     headers: {
      //       "x-api-key": process.env.REACT_APP_X_API_KEY,
      //       "Content-type": "application/json; charset=UTF-8",
      //     },
      //   })
      //   .then((data) => {
      //     console.log("article read success");
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }
  };
  const likePost = () => {
    const payload = [
      {
        userId: localStorage.getItem("registerE"),
        userName: localStorage.getItem("userName"),
        postId: postId,
        postTitle: postTitle,
        articleLikeTimestamp: new Date(Date.now()).getTime(),
        className: "article-like",
        publishDate: publishDate,
        dateTime: new Date(Date.now()).toISOString(),
        department: localStorage.getItem("department").split("/")[1].trim(),
      },
    ];
    axios
      .post(process.env.REACT_APP_API_URL, JSON.stringify(payload), {
        headers: {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((data) => {
        console.log("article like success");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const commentPost = () => {
    const payload = [
      {
        userId: localStorage.getItem("registerE"),
        userName: localStorage.getItem("userName"),
        postId: postId,
        postTitle: postTitle,
        articleCommentTimestamp: new Date(Date.now()).getTime(),
        className: "article-comment",
        publishDate: publishDate,
        dateTime: new Date(Date.now()).toISOString(),
        department: localStorage.getItem("department").split("/")[1].trim(),
      },
    ];
    axios
      .post(process.env.REACT_APP_API_URL, JSON.stringify(payload), {
        headers: {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((data) => {
        console.log("article comment success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const shareViaSMS = (title, URL) => {
    navigator
      .share({
        // Title that occurs over
        // web share dialog
        title: title,

        // URL to share
        url: URL,
      })
      .then(() => {
        console.log("Thanks for sharing!");
      })
      .catch((err) => {
        // Handle errors, if occured
        console.log("Error while using Web share API:");
        console.log(err);
      });
  };

  return (
    <>
      <div
        className={
          light
            ? condensed
              ? "post-actions light condensed"
              : "post-actions light"
            : "post-actions"
        }
      >
        <ul>
          <li>
            <a onClick={learnMore} href="#" className={buttonClass}>
              {!openOriginal ? (
                isVideo ? (
                  <div className="read-post">Play</div>
                ) : (
                  <div className="read-post">Read</div>
                )
              ) : (
                <div>Read on Amgen news</div>
              )}
            </a>
          </li>
          {/* <li
            onMouseEnter={() => setshowOptions(true)}
            onMouseLeave={() => setshowOptions(false)}
          >
            <a style={{ cursor: "pointer" }}>
              <img
                src="../../images/icons/share.svg"
                height="18px"
                width="18px"
              />
            </a>
            {showOptions && (
              <ul className="share-links" ref={shareRef}>
                <li>
                  <a
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://amgennews.amgen.com/member/post/${postId}`
                      );
                      setshowOptions(false);
                    }}
                  >
                    <img
                      src="../../images/icons/copy.png"
                      height="18px"
                      width="18px"
                      style={{ paddingBottom: "3px", paddingRight: "3px" }}
                    />
                    Copy Link
                  </a>
                </li>
                {navigator.share && (
                  <li>
                    <a
                      onClick={() => {
                        shareViaSMS(
                          postTitle,
                          `https://amgennews.amgen.com/member/post/${postId}`
                        );
                        setshowOptions(false);
                      }}
                    >
                      <img
                        src="../../images/icons/sms.png"
                        height="18px"
                        width="18px"
                        style={{ paddingBottom: "3px", paddingRight: "3px" }}
                      />
                      Share via SMS
                    </a>
                  </li>
                )}
                <li>
                  <a
                    onClick={() => {
                      setshowOptions(false);
                    }}
                    href={`mailto:?Subject=${postTitle}&body=${window.location.origin}/#${postId}`}
                    target={postId}
                  >
                    <img
                      src="../../images/icons/mail.png"
                      height="18px"
                      width="18px"
                      style={{ paddingBottom: "3px", paddingRight: "3px" }}
                    />
                    Share via Email
                  </a>
                  <iframe
                    id={postId}
                    name={postId}
                    style={{ visibility: "hidden", position: "absolute" }}
                  ></iframe>
                </li>
              </ul>
            )}
          </li> */}
          <li>
            <a
              onClick={likePost}
              target="_blank"
              href={`https://amgennews.amgen.com/member/post/${postId}?action=LikePost`}
            >
              {liked ? (
                <img
                  src="../../images/icons/thumbs-up.svg"
                  style={{ paddingBottom: "3px", paddingRight: "3px" }}
                />
              ) : (
                <img
                  src="../../images/icons/thumbs-up-outline.svg"
                  style={{ paddingBottom: "3px", paddingRight: "3px" }}
                />
              )}
              {statistics && statistics.likeCount && statistics.likeCount > 0
                ? statistics.likeCount
                : ""}
            </a>
          </li>
          <li>
            <a
              onClick={commentPost}
              target="_blank"
              href={`https://amgennews.amgen.com/member/post/${postId}?showDiscussions=True`}
            >
              <img
                src="../../images/icons/comments.png"
                height="18px"
                width="18px"
              />
              {statistics &&
              statistics.commentCount &&
              statistics.commentCount > 0
                ? statistics.commentCount
                : ""}
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default PostActions;
