import React, { useState, useRef, useEffect } from "react";
import "./App.scss";
import Navigation from "./components/Navigation/Navigation";
import Modal from "./utils/Modal";
import axios from "axios";
import Home from "./components/Home/Home";
import Headerslide from "./components/Headerslide/Headerslide";
import ConfigContext from "./ConfigContext";

function App() {
  const [alert, setalert] = useState(null);
  const [alertCollapse, setalertCollapse] = useState(false);
  const [showQuaterlyPerspective, setshowQuaterlyPerspective] = useState(false);
  const [configData, setconfigData] = useState(null);
  const headerRef = useRef();
  const fadeRef = useRef();

  useEffect(() => {
    axios.get("config.json").then((res) => {
      let response = res.data;
      setconfigData({
        ...response,
        featuredPosts: response.featuredPosts.sort((a, b) => a.order - b.order),
        posts: response.posts.sort((a, b) => a.order - b.order),
      });
      localStorage.setItem("x-api-key", process.env.REACT_APP_X_API_KEY);
      localStorage.setItem("USER_METRICS_URL", process.env.REACT_APP_API_URL);

      // if (
      //   res.data.quarterlyPerspective &&
      //   res.data.quarterlyPerspective.title &&
      //   res.data.quarterlyPerspective.href &&
      //   res.data.quarterlyPerspective.alert
      // ) {
      //   onAlert({
      //     title: "New Quarterly Perspective is here!",
      //     href: res.data.quarterlyPerspective.href,
      //   });
      // }
    });

    // window.addEventListener("scroll", (e) => {
    //   window.requestAnimationFrame(updateHeader);
    // });
  }, []);

  // const customAuthHandler = () => {
  //   navigate('/config.json');
  // };

  const onPerspectiveView = (event) => {
    event.preventDefault();
    setshowQuaterlyPerspective(true);
    const payload = [
      {
        userId: localStorage.getItem("registerE"),
        userName: localStorage.getItem("userName"),
        quaterlyPerspectiveTimestamp: new Date(Date.now()).getTime(),
        className: "quaterly-perspective",
        dateTime: new Date(Date.now()).toISOString(),
        department: localStorage.getItem("department").split("/")[1].trim(),
      },
    ];
    axios
      .post(process.env.REACT_APP_API_URL, JSON.stringify(payload), {
        headers: {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((data) => {
        console.log("Quaterly Perspective success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onAlert = (alert) => {
    setalert(alert);
    // Alert intersection observer
    let options = {
      rootMargin: "0px",
      threshold: 0,
    };
    let observer = new IntersectionObserver(([entry]) => {
      if (
        entry &&
        entry.isIntersecting &&
        entry.intersectionRatio >= options.threshold
      ) {
        setalertCollapse(true);
      } else {
        setalertCollapse(false);
      }
    }, options);
    setTimeout(() => {
      observer.observe(document.querySelector(".additional-news"));
    }, 10000);
  };

  // const updateHeader = () => {
  //   const additionalNews = document
  //     .querySelector(".additional-news")
  //     .getBoundingClientRect().top;
  //   if (additionalNews > 0) {
  //     headerRef.current.style.transform = `scale(${
  //       1 + window.scrollY / 10000
  //     })`;
  //     fadeRef.current.style.opacity = window.scrollY / 1000;
  //   }
  // };

  return (
    <>
      <ConfigContext.Provider value={{ configData }}>
        <div>
          <div className={alert ? "wrapper has-alert" : "wrapper"}>
            <div ref={headerRef} className="header-background"></div>
            {alert && (
              <div className={alertCollapse ? "alert collapse" : "alert"}>
                <div className="icon">
                  <svg
                    style={{ width: "24px", height: "24px" }}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M21,19V20H3V19L5,17V11C5,7.9 7.03,5.17 10,4.29C10,4.19 10,4.1 10,4A2,2 0 0,1 12,2A2,2 0 0,1 14,4C14,4.1 14,4.19 14,4.29C16.97,5.17 19,7.9 19,11V17L21,19M14,21A2,2 0 0,1 12,23A2,2 0 0,1 10,21M19.75,3.19L18.33,4.61C20.04,6.3 21,8.6 21,11H23C23,8.07 21.84,5.25 19.75,3.19M1,11H3C3,8.6 3.96,6.3 5.67,4.61L4.25,3.19C2.16,5.25 1,8.07 1,11Z"
                    />
                  </svg>
                </div>
                <div className="title">{alert.title}</div>
                <a className="href" onClick={onPerspectiveView} href="#">
                  VIEW
                </a>
              </div>
            )}
            <header>
              <div id="particles-js"></div>
              <Navigation className="navigation" />

              {/* <Headerslide /> */}
            </header>

            <div className="page-content">
              <Home />
            </div>

            {/* <div ref={fadeRef} className="fade"></div>
            <div className="fade-mask"></div> */}
          </div>
          {showQuaterlyPerspective && (
            <Modal handleClose={() => setshowQuaterlyPerspective(false)}>
              <iframe
                src={alert.url}
                id="perspectiveIframe"
                width="100%"
                height="980"
                style="border: 1px solid #EEE; background: white"
                frameborder="0"
                sandbox="allow-scripts"
                scrolling="no"
              ></iframe>
            </Modal>
          )}
        </div>
      </ConfigContext.Provider>
    </>
  );
}

export default App;
