import React, { useState, useEffect, useRef, useContext } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Post from "../Posts/Posts";
import CategoryTab from "../CategoryTab/CategoryTab";
import QuarterlyPerspective from "../QuarterlyPerspective/QuarterlyPerspective";
import Footer from "../Footer/Footer";
import "./Home.scss";
import dayjs from "dayjs";
import PostContent from "../PostContent/PostContent";
import axios from "axios";
import Feedback from "../Feedback/Feedback";
import PostSlider from "../PostSlider/PostSlider";
import Loader from "../Common/Loader";
import ConfigContext from "../../ConfigContext";

function Home() {
  const { configData } = useContext(ConfigContext);
  const [selectedSlide, setselectedSlide] = useState(null);
  const [tabContentHeight, settabContentHeight] = useState("auto");
  const [mobile, setmobile] = useState(false);
  const [slideShowInterval, setslideShowInterval] = useState(null);
  const [allCategories, setallCategories] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [categories, setcategories] = useState([]);
  const [posts, setposts] = useState([]);
  const [allPosts, setallPosts] = useState([]);
  const [allPostIds, setallPostIds] = useState([]);
  const [currentPageSize, setcurrentPageSize] = useState(5);
  const [quarterlyPerspective, setquarterlyPerspective] = useState({});
  const [featuredPostlength, setfeaturedPostlength] = useState(0);
  const [featured, setfeatured] = useState([]);
  const [otherNewsCategories, setotherNewsCategories] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const categoryCondensedDisplayWidth = 992;
  const contentRef = useRef();

  const categoryInfo = {
    694: {
      icon: "../../images/icons/big-tech.svg",
      image: "../../images/exports/category_bigTech.png",
    },
    700: {
      icon: "../../images/icons/big-tech.svg",
      image: "../../images/exports/category_bigTech.png",
    },
    696: {
      icon: "../../images/icons/startups.svg",
      image: "../../images/exports/category_startups.png",
    },
    705: {
      icon: "../../images/icons/startups.svg",
      image: "../../images/exports/category_startups.png",
    },
    695: {
      icon: "../../images/icons/pharma-peers.svg",
      image: "../../images/exports/category_pharmaPeers.png",
    },
    704: {
      icon: "../../images/icons/pharma-peers.svg",
      image: "../../images/exports/category_pharmaPeers.png",
    },
    697: {
      icon: "../../images/icons/payers-providers.svg",
      image: "../../images/exports/category_payersProviders.png",
    },
    706: {
      icon: "../../images/icons/payers-providers.svg",
      image: "../../images/exports/category_payersProviders.png",
    },
    703: {
      icon: "../../images/icons/regulator-policy.svg",
      image: "../../images/exports/category_regulatoryPolicy.png",
    },
    707: {
      icon: "../../images/icons/regulator-policy.svg",
      image: "../../images/exports/category_regulatoryPolicy.png",
    },
  };

  const hasPerspective = (post) => {
    let result;
    let hasPerspective = false;
    const regMatch = /<blockquote.+>(.+)<\/blockquote>/g;
    while ((result = regMatch.exec(post || "")) !== null) {
      if (
        result &&
        result[0] &&
        result[0].toLowerCase().includes("dh&i perspective")
      ) {
        hasPerspective = true;

        post = post.replace(
          result[0],
          '<div className="perspective">' + result[0] + "</div>"
        );
      }
    }
    return hasPerspective ? post : false;
  };

  const getPostByCategory = (categoryId) => {
    const category =
      (posts || []).find((category) => categoryId === category.id) || {};
    return (category.posts || []).slice(0, 16);
  };

  const checkCategoryDisplayMode = () => {
    if (window.innerWidth >= categoryCondensedDisplayWidth) {
      setmobile(false);
    } else {
      setmobile(true);
    }
  };

  const setSlideHeight = () => {
    // console.log(contentRef.current)
    let el = document.getElementById("news-category");
    // if (mobile || !el) {
    //   settabContentHeight("auto")
    //   return;
    // }

    //settabContentHeight(contentRef.current.clientHeight + "px");
    settabContentHeight("598px");
  };

  useEffect(() => {
    if (configData) {
      setstartDate(dayjs(configData.startDate).format("MM.DD.YY") || "");
      setendDate(dayjs(configData.endDate).format("MM.DD.YY") || "");
      setquarterlyPerspective(configData.quarterlyPerspective);
      loginInfo();
      checkCategoryDisplayMode();
      window.addEventListener("resize", checkCategoryDisplayMode);
      setshowLoader(true);
      const getAllCategories = async () => {
        const config = {
          method: "get",
          url: `${configData.APP_URL}/categories`,
          // headers: {
          //   Authorization: "Bearer " + AMGEN_NEWS_TOKEN,
          // },
        };
        const {
          data: { categories: allCategories },
        } = await axios(config);
        setallCategories(allCategories);
      };
      getAllCategories();
    }
    return () => {
      window.removeEventListener("resize", checkCategoryDisplayMode);
    };
  }, [configData]);

  useEffect(() => {
    const getEachPost = async (childCategories) => {
      //const allPosts = {};
      // const header = {
      //   "Content-Type": "application/json",
      //   // Authorization: "Bearer " + AMGEN_NEWS_TOKEN,
      //   redirect: "follow",
      //   Accept: "application/json",
      // };

      // for (const postId of config.posts) {
      //   // Use of loop to wait for promise to complete
      //   const { data } = await axios.get(
      //     `https://cu61avsr8b.execute-api.us-west-2.amazonaws.com/dev/amgen-news/post/${postId}`,
      //     // {
      //     //   headers: header,
      //     // }
      //   );
      //   if (data) {
      //     const perspective = hasPerspective(data.content);
      //     if (perspective) {
      //       data.perspective = !!perspective;
      //       data.content = perspective;
      //     }
      //     allPosts[postId] = data;
      //     if (data.categories && data.categories.length) {
      //       // Loop through the categories and add the post to each if exists
      //       data.categories.forEach(({ id: postCategoryId }) => {
      //         const categoryIndex = childCategories.findIndex(({ id }) => {
      //           return id === postCategoryId;
      //         });
      //         if (categoryIndex > -1) {
      //           childCategories[categoryIndex].posts.push(data);
      //         }
      //       });
      //     }
      //   }
      // }

      setfeaturedPostlength(configData.featuredPosts.length);
      setallPostIds(configData.posts.map((post) => post.id));
      setcurrentPageSize(configData.pageSize);
      let currentPosts = configData.posts.slice(0, configData.pageSize);
      let allposts = currentPosts.concat(configData.featuredPosts);
      let allPostIds = allposts.map((post) => post.id);
      axios
        .get(`${configData.APP_URL}/posts?ids=${allPostIds.join(",")}`)
        .then((res) => {
          // setallPosts(configData.posts);
          res.data.posts.forEach((data) => {
            const perspective = hasPerspective(data.content);
            if (perspective) {
              data.perspective = !!perspective;
              data.content = perspective;
            }
            //allPosts[data.postId] = data;
            if (data.categories && data.categories.length) {
              // Loop through the categories and add the post to each if exists
              data.categories.forEach(({ id: postCategoryId }) => {
                const categoryIndex = childCategories.findIndex(({ id }) => {
                  return id === postCategoryId;
                });
                if (categoryIndex > -1) {
                  childCategories[categoryIndex].posts.push(data);
                }
              });
            }
          });
          setallPosts(res.data.posts);
          setcategories(childCategories);
          //setposts(childCategories);
          setshowLoader(false);
        });
    };
    if (allCategories.length > 0) {
      let { childCategories } = allCategories.find(
        (category) => "the download" === category.name.toLowerCase()
      );
      // Filter out pending categories - this can be removed later
      childCategories = childCategories
        .filter((category) => {
          return !category.name.startsWith("Pending");
        })
        .map((category) => {
          return {
            ...category,
            posts: [],
            name: category.name.replace("Pending - ", ""),
          };
        });
      getEachPost(childCategories);
    }
  }, [allCategories]);

  useEffect(() => {
    if (allPosts.length > 0) {
      setfeatured(
        allPosts.slice(allPosts.length - featuredPostlength, allPosts.length)
      );
      const otherPosts = allPosts;
      otherPosts.splice(
        allPosts.length - featuredPostlength,
        featuredPostlength
      );
      // setallPosts(otherPosts)
      setposts(otherPosts);
    }
  }, [allPosts]);

  useEffect(() => {
    if (categories.length > 0) {
      const sortList = [
        "big tech",
        "start ups",
        "biopharma peers",
        "payers providers",
        "regulatory & policy",
        "General",
      ];

      const NewsCategories = categories
        .filter((category) => -1 === [698, 693].indexOf(category.id))
        .sort((a, b) => {
          return (
            sortList.indexOf(a.name.toLowerCase()) -
            sortList.indexOf(b.name.toLowerCase())
          );
        })
        .map((category) => {
          const current = categoryInfo[category.id] || {};
          return { ...category, ...current };
        });
      setotherNewsCategories(NewsCategories);
      setselectedSlide(NewsCategories[0].id);
      setSlideHeight();
    }
  }, [categories]);

  const loadMore = () => {
    let currentPosts = allPostIds.slice(
      currentPageSize,
      currentPageSize + configData.pageSize
    );
    setcurrentPageSize(currentPageSize + configData.pageSize);
    let childCategories = categories;
    setshowLoader(true);
    axios
      .get(`${configData.APP_URL}/posts?ids=${currentPosts.join(",")}`)
      .then((res) => {
        // setallPosts(configData.posts);
        res.data.posts.forEach((data) => {
          const perspective = hasPerspective(data.content);
          if (perspective) {
            data.perspective = !!perspective;
            data.content = perspective;
          }
          //allPosts[data.postId] = data;
          if (data.categories && data.categories.length) {
            // Loop through the categories and add the post to each if exists
            data.categories.forEach(({ id: postCategoryId }) => {
              const categoryIndex = childCategories.findIndex(({ id }) => {
                return id === postCategoryId;
              });
              if (categoryIndex > -1) {
                childCategories[categoryIndex].posts.push(data);
              }
            });
          }
        });
        const previousPosts = JSON.parse(JSON.stringify(posts));
        const newPosts = previousPosts.concat(res.data.posts);
        setposts(newPosts);
        setcategories(childCategories);
        // setposts(childCategories);
        setshowLoader(false);
      });
  };
  const loginInfo = () => {
    localStorage.setItem("articleReadCount", 0);
    const payload = [
      {
        className: "first-time-login",
        userId: localStorage.getItem("registerE"),
        userName: localStorage.getItem("userName"),
        functionName: localStorage.getItem("functionName"),
        loginTimestamp: new Date(Date.now()).getTime(),
        dateTime: new Date(Date.now()).toISOString(),
        department: localStorage.getItem("department").split("/")[1].trim(),
      },
    ];
    localStorage.setItem("loginTimestamp", new Date(Date.now()).getTime());
    axios
      .post(process.env.REACT_APP_API_URL, JSON.stringify(payload), {
        headers: {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((data) => {
        console.log(" success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="page">
        {showLoader ? <Loader showLoader /> : null}
        <div className="container first-item">
          <p className="article-grid alt-grid text-white pb-0 pb3">
            {/* <p className="article-grid alt-grid text-white pb-3"> */}
            {/* Latest News {startDate} - {endDate} */}
          </p>
          <section className="row">
            {featured.map((post, index) => {
              if ((index + 1) % 3 === 0) {
                return (
                  <article className="graybg" key={`latest-${post.postId}`}>
                    <Post post={post} card="true" index={index} />
                  </article>
                );
              } else {
                return (
                  <article
                    className="col-md-6 all-post"
                    key={`latest-${post.postId}`}
                  >
                    <Post post={post} card="true" index={index} />
                  </article>
                );
              }
            })}
          </section>
        </div>

        <Feedback featuredPosts={featured} posts={posts} />
        {posts.length > 0 && (
          <section className="additional-news">
            <div className="tag">ADDITIONAL FEATURED PROGRAMS</div>
            <div className="container">
              {/* {!mobile && (
              <div className="tabs">
                {otherNewsCategories.map((category) => {
                  return (
                    <CategoryTab
                      key={category.id}
                      title={category.name}
                      icon={category.icon}
                      image={category.image}
                      selected={category.id === selectedSlide}
                      click={() => {setselectedSlide(category.id);setSlideHeight()}}
                    />
                  );
                })}
              </div>
            )} */}
              <div className="tab-content">
                <div id="news-category" ref={contentRef}>
                  <div>
                    {posts && posts.length > 0 && (
                      <PostSlider
                        posts={posts}
                        slideChanged={setSlideHeight}
                        border={true}
                        loadMore={loadMore}
                        showLoadMore={configData.posts.length > currentPageSize}
                      />
                    )}
                  </div>
                  {/* {otherNewsCategories.map((category) => {
                    return (
                      <div
                        key={category.id}
                        className="slide"
                        style={{
                          display:
                            mobile || category.id === selectedSlide
                              ? ""
                              : "none",
                        }}
                      >
                        {mobile && (
                          <CategoryTab
                            key={category.id}
                            title={category.name}
                            icon={category.icon}
                            image={category.image}
                          />
                        )}
                        <PostSlider
                          posts={getPostByCategory(category.id)}
                          slideChanged={setSlideHeight}
                        />
                      </div>
                    );
                  })} */}
                </div>
              </div>
            </div>
          </section>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default Home;
