import React, { useState, useContext, useEffect } from "react";
import "./Posts.scss";
import PostActions from "../PostActions/PostActions";
import axios from "axios";
// import Popupmodel from "../popup/popup";
import PostContent from "../PostContent/PostContent";
import Modal from "../../utils/Modal";
import ConfigContext from "../../ConfigContext";

const Post = ({ post, hero, card, showbroder, index, border }) => {
  const [showVideo, setshowVideo] = useState(false);
  const [featureVideo, setfeatureVideo] = useState(null);
  const [badgeTitle, setbadgeTitle] = useState(null);
  const [horizontalCard, sethorizontalCard] = useState(false);
  const { configData } = useContext(ConfigContext);
  const [onclickhendler, setOnclickhendler] = useState(false);
  const [showicon, setShowicon] = useState(false);
  const image = (post.images && post.images.Box640) || post.images.Box320 || {};

  const wordBreak = (text, length) => {
    if (text.length > 0) {
      let ltext = text.split(" ");
      return ltext.slice(0, length).join(" ");
    } else {
      return "";
    }
  };

  useEffect(() => {
    let pst = configData.posts.find((pst) => pst.id === post.postId);
    let ftpst = configData.featuredPosts.find((pst) => pst.id === post.postId);
    if (pst && pst.category) {
      setbadgeTitle(pst.category);
      sethorizontalCard(true);
    }
    if (ftpst && ftpst.category) {
      setbadgeTitle(ftpst.category);
      sethorizontalCard(true);
    }
  }, [post]);

  const openModal = (e) => {
    setOnclickhendler(true);
    document.body.classList.add("noscroll");
    let initialCount = Number(localStorage.getItem("articleReadCount"));
    localStorage.setItem("articleReadCount", initialCount + 1);
    const payload = [
      {
        userId: localStorage.getItem("registerE"),
        userName: localStorage.getItem("userName"),
        postId: post.postId,
        postTitle: post.title,
        articleReadTimestamp: new Date(Date.now()).getTime(),
        className: "article-read",
        publishDate: post.publishDate,
        dateTime: new Date(Date.now()).toISOString(),
        department: localStorage.getItem("department").split("/")[1].trim(),
      },
    ];
    axios
      .post(process.env.REACT_APP_API_URL, JSON.stringify(payload), {
        headers: {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((data) => {
        console.log("article read success");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const horizontalFeatureCard = (index + 1) % 3 === 0 ? true : false;

  const getPostVideoURL = () => {
    axios
      .get(`${configData.APP_URL}/post/${post.postId}?include=WatchVideo`)
      .then((videoRes) => {
        let featureVideo = videoRes.data.media.filter(
          (media) => media.role === "video-hosted"
        )[0];
        setfeatureVideo(featureVideo);
        setshowVideo(true);
      });
  };

  const mouseOverHandler = (data) => {
    setShowicon(data);
  };

  return (
    <>
      {onclickhendler && (
        <Modal
          handleClose={() => {
            setOnclickhendler(false);
            document.body.classList.remove("noscroll");
          }}
        >
          <PostContent post={post} />
        </Modal>
      )}
      <div
        className={
          card && !horizontalFeatureCard ? "post card" : "post standard"
        }
        onMouseLeave={
          card && !horizontalFeatureCard
            ? () => mouseOverHandler(false)
            : () => {}
        }
        onMouseEnter={
          card && !horizontalFeatureCard
            ? () => mouseOverHandler(true)
            : () => {}
        }
      >
        {hero ? (
          <div>
            {image.url && post.postType !== "Video" && (
              <div
                onClick={openModal}
                className="feature-image hero"
                style={{ backgroundImage: `url(${image.url})` }}
              >
                {post.perspective && card && (
                  <div>
                    <div className="perspective-badge-border d-none"></div>
                    <div className="perspective-badge d-none">
                      <img
                        className="chat-icon"
                        src="../../images/icons/TheDownload_mark.svg"
                      />
                      <span>Perspective</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            {post.postType === "Video" && !showVideo && (
              <div
                onClick={getPostVideoURL}
                className="feature-image hero"
                style={{ backgroundImage: `url(${image.url})` }}
              >
                <img
                  className="video-thumb "
                  src="../../images/video_thumb.png"
                />
                {post.perspective && card && (
                  <div>
                    <div className="perspective-badge-border"></div>
                    <div className="perspective-badge">
                      <img
                        className="chat-icon"
                        src="../../images/icons/TheDownload_mark.svg"
                      />
                      <span>Perspective</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            {showVideo && (
              <div>
                <video
                  src={featureVideo.url}
                  width={featureVideo.width}
                  className="feature-image video hero"
                  autoPlay
                  muted
                  controls
                >
                  Your browser does not support the video tag.
                </video>
                {post.perspective && card && (
                  <div>
                    <div className="perspective-badge-border"></div>
                    <div className="perspective-badge">
                      <img
                        className="chat-icon"
                        src="../../images/icons/TheDownload_mark.svg"
                      />
                      <span>Perspective</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="content">
              <h3 className="clamp-3">
                <a className="post-title" onClick={openModal}>
                  {post.title}
                </a>
              </h3>
              <p className="clamp-4">{post.description}</p>

              <PostActions
                postId={post.postId}
                postTitle={post.title}
                publishDate={post.publishDate}
                isVideo={post.postType === "Video"}
                openModal={openModal}
              />
            </div>
          </div>
        ) : (
          <div className={index % 2 !== 1 ? "row" : "row reverse-post"}>
            {(card && horizontalCard) || (showbroder && !border) ? (
              <div className="perspective-badge-development">
                <span>{badgeTitle}</span>
              </div>
            ) : (
              ""
            )}
            <div className="col-xl-6 col-md-6" id="graybg-img">
              {image.url && post.postType !== "Video" && (
                <div
                  onClick={openModal}
                  className="feature-image non-hero"
                  style={{ backgroundImage: `url(${image.url})` }}
                >
                  {showicon ? (
                    <img
                      src="/images/icons/launch_black.png"
                      className="hove-icon"
                    />
                  ) : (
                    ""
                  )}
                  {post.perspective && card && (
                    <div>
                      {/* <div className="perspective-badge-border"></div> */}
                      <div className="perspective-badge">
                        <img
                          className="chat-icon"
                          src="../../images/icons/TheDownload_mark.svg"
                        />
                        <span>Perspective</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {post.postType === "Video" && !showVideo && (
                <div
                  onClick={getPostVideoURL}
                  className="feature-image hero"
                  style={{ backgroundImage: `url(${image.url})` }}
                >
                  <img
                    className="video-thumb "
                    src="../../images/video_thumb.png"
                  />
                  {post.perspective && card && (
                    <div>
                      <div className="perspective-badge-border"></div>
                      <div className="perspective-badge">
                        <img
                          className="chat-icon"
                          src="../../images/icons/TheDownload_mark.svg"
                        />
                        <span>Perspective</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {showVideo && (
                <div>
                  <video
                    src={featureVideo.url}
                    width={featureVideo.width}
                    className="feature-image video hero"
                    autoPlay
                    muted
                    controls
                  >
                    Your browser does not support the video tag.
                  </video>
                  {post.perspective && card && (
                    <div>
                      <div className="perspective-badge-border"></div>
                      <div className="perspective-badge">
                        <img
                          className="chat-icon"
                          src="../../images/icons/TheDownload_mark.svg"
                        />
                        <span>Perspective</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="col-xl-6 col-md-6 cal-6 gray-padding">
              <div className="content">
                <h3 className="clamp-3" onClick={openModal}>
                  <a className="post-title">{post.title}</a>
                </h3>
                <p className="clamp-4">{post.description}</p>

                <PostActions
                  postId={post.postId}
                  postTitle={post.title}
                  publishDate={post.publishDate}
                  isVideo={post.postType === "Video"}
                  openModal={openModal}
                  statistics={post.statistics}
                />
              </div>
            </div>

            {/* <Popupmodel post={post}  /> */}
          </div>
        )}
        {/* {border || showbroder ? <hr className="border-line mt-5" />:''} */}
      </div>
      {showbroder ? <hr className="border-line" /> : ""}
    </>
  );
};

export default Post;
