import React from 'react'
import './Navigation.scss'

function Navigation() {
  return (
    <nav className='nav'>
        <div className='nav-heading text-center'>
           <img src="/images/logo/logo.svg" />
        </div>

        <div className='nav-desc text-center'>
           <p><b>Artificial Intelligence & Automation</b> Efforts <br/> at <b>Amgen</b></p>
        </div>
    </nav>
  )
}

export default Navigation