import React, { useContext, useEffect, useState, useRef } from "react";
import ConfigContext from "../../ConfigContext";
import "./Feedback.scss";
import Modal from "../../utils/Modal";
import PostContent from "../PostContent/PostContent";
import axios from "axios";

const Feedback = ({ featuredPosts, posts }) => {
  const { configData } = useContext(ConfigContext);
  const [currentIndex, setcurrentIndex] = useState(0);
  const [onclickhendler, setOnclickhendler] = useState(false);
  const [post, setPost] = useState(null);

  useEffect(() => {
    let index = 0;
    let timeout = null;
    if (configData) {
      timeout = setInterval(() => {
        if (configData.quotes.length - 1 === index) {
          index = 0;
          setcurrentIndex(index);
        } else {
          index += 1;
          setcurrentIndex(index);
        }
      }, configData.slideTime * 1000);
    }

    return () => {
      clearInterval(timeout);
    };
  }, [configData]);

  const openModal = (postId) => {
    let post = featuredPosts.find((pst) => pst.postId === postId);
    if (post) {
      setPost(post);
    } else {
      post = posts.find((pst) => pst.postId === postId);
      setPost(post);
    }
    setOnclickhendler(true);
    document.body.classList.add("noscroll");
    let initialCount = Number(localStorage.getItem("articleReadCount"));
    localStorage.setItem("articleReadCount", initialCount + 1);
    const payload = [
      {
        userId: localStorage.getItem("registerE"),
        userName: localStorage.getItem("userName"),
        postId: post.postId,
        postTitle: post.title,
        articleReadTimestamp: new Date(Date.now()).getTime(),
        className: "article-read",
        publishDate: post.publishDate,
        dateTime: new Date(Date.now()).toISOString(),
        department: localStorage.getItem("department").split("/")[1].trim(),
      },
    ];
    axios
      .post(process.env.REACT_APP_API_URL, JSON.stringify(payload), {
        headers: {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((data) => {
        console.log("article read success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {onclickhendler && post && (
        <Modal
          handleClose={() => {
            setOnclickhendler(false);
            setPost(null);
            document.body.classList.remove("noscroll");
          }}
        >
          <PostContent post={post} />
        </Modal>
      )}
      <div>
        <section className="feedback-box">
          <div className="container animation-container">
            {configData &&
              configData.quotes.map((quote) => {
                return (
                  <div
                    key={quote.id}
                    className={
                      quote.id === currentIndex + 1
                        ? "quote-enter-active"
                        : "quote-exit-active"
                    }
                  >
                    {quote.id === currentIndex + 1 && (
                      <div style={{ textAlign: "center" }}>
                        <div>
                          <h6 className="text-center quote-title">
                            {quote.title}
                          </h6>
                        </div>
                        <div className="text-center pt-3">
                          {quote.postId && (
                            <a
                              onClick={() => openModal(quote.postId)}
                              className="btn btn-outline-light border learn-more"
                            >
                              Learn more
                            </a>
                          )}
                        </div>
                        <div className="pt-3">
                          <p className="qoute-description">
                            {quote.description}
                          </p>
                          <div className="text-center hr"></div>
                          <p className="text-center quote-author">
                            {quote.author} - {quote.profession}
                          </p>
                          {/* <p className="text-center quote-author-profession">
                      <i>{quote.profession}</i>
                    </p> */}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </section>
      </div>
    </>
  );
};

export default Feedback;
