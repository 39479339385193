import React, {useState,useEffect,useContext } from "react";
import dayjs from "dayjs";
import "./Footer.scss";
import ConfigContext from "../../ConfigContext";

const Footer = () => {
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const { configData } = useContext(ConfigContext);
  useEffect(() => {
    if(configData) {
      setstartDate(dayjs(configData.startDate).format("MM.DD.YY") || "");
      setendDate(dayjs(configData.endDate).format("MM.DD.YY") || "");
    }
      
  }, [configData]);

  return (
    <>
      <footer>
        <div className="footer" style={{paddingBottom: '10px'}}>
          <div>
            {/* <a href="#" className="logo">
              <img src="../../images/logo-dark.svg" />
            </a> */}
            {/* <h1 className="footer-heading"><b>AI</b>&amp;Automation</h1> */}
            <img src="./images/logo/logo.svg" />
          </div>
          <div className="vl">
            <div></div>
          </div>
          <div className="footer-border">
            {/* <strong className="footer-status">
              Latest News { startDate } - { endDate }
            </strong> */}
            <p className="footer-description"><b>Tech Innovation @ Amgen</b> highlights efforts underway advancing our mission of serving patients through digital solutions leveraging Artificial Intelligence and Automation.</p>
          </div>

     </div>
     <div className="footer" style={{paddingTop: '10px'}}>
          <div>
            <img src="./images/logo.svg" className="footer-img" />
          </div>
          <div className="vl">
            <div></div>
          </div>
          <div className="footer-border">
            <p className="footer-description">Also Available: The Download channel provides a biweekly review of key external health technology news. <a className="subscribe-link" target="_blank" href="https://teams.microsoft.com/l/channel/19%3AJ9SWB1krXywFu_YSESYDSuGz64NMzGWa9QxmtGvtaD41%40thread.tacv2/tab%3A%3A06cf6301-9d22-4010-aead-b93252ede6ae?groupId=3a3470ac-e3dd-42ec-9912-1cbb29066d5b&tenantId=4b4266a6-1368-41af-ad5a-59eb634f7ad8&allowXTenantAccess=false">Click here</a> to subscribe.</p>
          </div>

     </div>
     <div className="footer-bar-unsubscribe">
      <div className="unsub-link">
      <a target="_blank" className="unsub-link" href="https://app.smartsheet.com/b/form/fccfb4e0f1f34b6c92f8314110852242">Unsubscribe</a> from Tech Innovation @ Amgen
      </div>
     </div>
        <div className="footer-bar">
          <a href="#" className="logo">
            <img src="../../images/amgen-logo.svg" />
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
